<template>
  <div class="main-container">
    <!--查询区域start-->
    <div class="search-area">
      <div class="form-area">
        <el-form class="search-form" ref="search-form" :model="searchForm" label-width="100px">
          <el-form-item label="昵称">
            <el-input v-model="searchForm.userName" clearable></el-input>
          </el-form-item>
          <el-form-item label="账号">
            <el-input v-model="searchForm.userId" clearable></el-input>
          </el-form-item>
          <el-form-item label="状态">
            <el-select v-model="searchForm.status">
              <el-option label="登录" value="1"></el-option>
              <el-option label="登出" value="0"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="登录时间">
            <el-date-picker v-model="searchForm.date" type="datetimerange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" align="right" value-format="yyyy-MM-dd HH:mm:ss"> </el-date-picker>
          </el-form-item>
        </el-form>
      </div>
      <div class="search-btn-area">
        <el-button type="primary" class="search-btn" @click="queryUserList" :loading="queryLoading">查询</el-button>
        <el-button class="reset-btn" @click="resetBtn">重置</el-button>
      </div>
    </div>
    <!--查询区域end-->

    <!-- 表格区域start -->
    <div class="table-area">
      <div class="operate-btn">
        <!--            <el-button v-check-auth="['sys#user#add1']" type="primary" class="add-btn" @click="addDialogOpen"><i class="el-icon-el-icon-download"></i>导出</el-button>-->
        <el-button type="primary" class="add-btn" @click="exportBtn" :loading="exportLoading" icon="el-icon-download">导出</el-button>
      </div>
      <el-table ref="resultTable" :data="tableList" tooltip-effect="dark" v-loading="tableLoading">
        <el-table-column prop="id" label="日志编号" width="180"> </el-table-column>
        <el-table-column prop="userId" label="账号" width="180"> </el-table-column>
        <el-table-column prop="userName" label="用户名称" min-width="180"> </el-table-column>
        <el-table-column prop="userIp" label="登录ip" width="180"> </el-table-column>
        <el-table-column prop="loginLocation" label="地理位置" width="180"> </el-table-column>
        <el-table-column prop="loginTime" label="登录时间" width="180"> </el-table-column>
        <el-table-column prop="browser" label="浏览器" width="180"> </el-table-column>
        <el-table-column prop="os" label="操作系统" width="180"> </el-table-column>
        <el-table-column prop="status" label="状态" width="100">
          <template v-slot="scope">
            {{ scope.row.status === '1' ? '登录' : '登出' }}
          </template>
        </el-table-column>
        <el-table-column label="操作" width="80">
          <template v-slot="scope">
            <el-button type="text" @click="detailBtn(scope.row)">详情</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination-area">
        <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="paginationConfig.pageNum" :page-sizes="[10, 20, 50, 100]" :page-size="paginationConfig.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="paginationConfig.total"> </el-pagination>
      </div>
    </div>
    <!-- 表格区域end -->

    <!-- 详情弹窗区域start -->
    <el-dialog title="日志详情" :visible.sync="dialogDetailVisible" close-on-click-modal>
      <el-descriptions class="margin-top" title="" :column="3">
        <el-descriptions-item label="日志编号">{{ detailData.id }}</el-descriptions-item>
      </el-descriptions>
      <el-descriptions class="margin-top" title="" :column="3">
        <el-descriptions-item label="账号">{{ detailData.userId }}</el-descriptions-item>
        <el-descriptions-item label="用户名称">{{ detailData.userName }}</el-descriptions-item>
        <el-descriptions-item label="登录IP">{{ detailData.userIp }}</el-descriptions-item>
        <el-descriptions-item label="地理位置">{{ detailData.loginLocation }}</el-descriptions-item>
        <el-descriptions-item label="浏览器">{{ detailData.browser }}</el-descriptions-item>
        <el-descriptions-item label="操作系统">{{ detailData.os }}</el-descriptions-item>
        <el-descriptions-item label="状态">
          <el-tag size="small">{{ detailData.status === '1' ? '登录' : '登出' }}</el-tag>
        </el-descriptions-item>
      </el-descriptions>
      <el-descriptions>
        <el-descriptions-item label="登录时间">{{ detailData.loginTime }}</el-descriptions-item>
      </el-descriptions>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogDetailVisible = false">关 闭</el-button>
      </div>
    </el-dialog>
    <!-- 详情弹窗区域start -->
  </div>
</template>

<script>
import { getLoginLogPagination } from '@/service/api/audit/log'
export default {
  name: 'LoginLog',
  data() {
    return {
      /**
       * 交互控制信号
       */
      // 查询按钮加载
      queryLoading: false,
      // 表格加载
      tableLoading: false,
      // 导出按钮加载
      exportLoading: false,
      // 弹窗显示控制
      dialogDetailVisible: false,
      /**
       * 表单
       */
      searchForm: {},
      // 分页数据
      paginationConfig: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      /**
       * 显示数据
       */
      // 表格数据
      tableList: [],
      // 详情弹窗数据
      detailData: {}
    }
  },
  created() {
    this.getList()
  },
  methods: {
    /**
     * 按钮事件
     */
    // 重置按钮
    resetBtn() {
      this.searchForm = {}
      this.getList()
    },
    // 查询按钮
    queryUserList() {
      this.paginationConfig.pageNum = 1
      this.getList()
    },
    getList() {
      const data = Object.assign({}, this.searchForm, {
        pageNum: this.paginationConfig.pageNum,
        pageSize: this.paginationConfig.pageSize,
        startTime: this.searchForm.date && this.searchForm.date[0],
        endTime: this.searchForm.date && this.searchForm.date[1]
      })
      delete data.date
      this.tableLoading = true
      getLoginLogPagination(data)
        .then(res => {
          if (res.data.status === 200) {
            this.tableList = res.data.data
            this.paginationConfig.total = Number(res.data.total)
          }
          this.tableLoading = false
        })
        .catch(() => {
          this.$message.error('服务器错误')
          this.tableLoading = false
        })
    },
    // 导出按钮
    exportBtn() {},
    // 详情按钮
    detailBtn(row) {
      this.dialogDetailVisible = true
      this.detailData = row
    },
    /**
     * 分页事件
     */
    // 页大小切换
    handleSizeChange(val) {
      this.paginationConfig.pageSize = val
    },
    // 页码变更
    handleCurrentChange(val) {
      this.paginationConfig.pageNum = val
    }
  }
}
</script>

<style scoped lang="scss">
@import '~$assets/css/common/search-area.scss';
@import '~$assets/css/common/table-area.scss';
.main-container {
  width: 100%;
  box-sizing: border-box;
  .search-area {
    margin: 0;
    padding: 0;
  }
  .table-area {
    margin: 0;
    padding: 0;
  }
  .pagination-area {
    display: flex;
    justify-content: flex-end;
  }
  ::v-deep.el-dialog {
    .el-dialog__body {
      padding: 20px;
    }
  }
}
</style>
